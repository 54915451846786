import Cca from "./../../assets/CCA.jpg";
import coex2 from "./../../assets/CoExFIT.jpg";
import coex3 from "./../../assets/CoExJuegoFIT.jpg";
import mfp from "./../../assets/MFP.png";
import msb2 from "./../../assets/MSBv2.jpeg";
import naya from "./../../assets/NAYA.jpg";
import vgl from "./../../assets/VGL.png";
import beryll from "./../../assets/beryll.jpg";
import coex from "./../../assets/coloniaexpress.jpg";
import fds from "./../../assets/fds.jpg";
import garpar from "./../../assets/garpar.jpg";
import huega from "./../../assets/huega.jpg";
import lindye from "./../../assets/lindye.jpg";
import nhf from "./../../assets/nohayflix.png";
import osdi from "./../../assets/osdi.png";
import pwa1 from "./../../assets/pwa1.jpg";
import pwa2 from "./../../assets/pwa2.jpg";
import pwa3 from "./../../assets/pwa3.jpg";
import riuBodas from "./../../assets/riu-bodas.jpg";
import samana from "./../../assets/samana.jpg";
import msb from "./../../assets/www.msbroker.com.ar.jpg";
import vglport from "./../../assets/www.vgl.com.ar.png";
import ylou from "./../../assets/ylou.com.ar.png";

function Blog() {
  return (
    <>
      <main className='df'>
        <h1>Blog</h1>
        <p>
          In this space I keep record of different experiences. Own designs,
          functional pages and prototypes.
        </p>
        <a
          className='github'
          target='_blank'
          rel='noreferrer'
          href='https://github.com/ValenGL'
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 512'>
            <path d='M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z' />
          </svg>
        </a>

        <div className='entry'>
          <p className='entry-date'>Today</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Your new App</h2>
              <h4 className='my-4'>
                Do you have a great idea? Get in touch and start working
                together.
              </h4>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>08-2024</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Huega House Ecommerce</h2>
              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://huegahouse.com/'
              >
                Huega House Ecommerce
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={huega}
                  alt='Huega House site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>07-2024</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Samana Costa Rica Ecommerce</h2>
              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://samanacostarica.com/en'
              >
                Samana Costa Rica Ecommerce
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={samana}
                  alt='Samana Costa Rica site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>05-2024</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Lindye Galloway - LeMae Ecommerce</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://shop.lindyegalloway.com/'
              >
                Lindye Galloway - LeMae Ecommerce
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={lindye}
                  alt='Lindye Galloway - LeMae site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>03-2024</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>+Beryll Ecommerce</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://beryll.com/'
              >
                +Beryll Shopify Ecommerce
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={beryll}
                  alt='+Beryll site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>02-2024</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>RIU Bodas</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://www.riu.com/es/weddings'
              >
                RIU Bodas
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={riuBodas}
                  alt='RIU bodas site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>01-2024</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>GARPAR.ar</h2>
              <div
                style={{
                  display: "flex",
                  padding: "1rem",
                  alignItems: "center,",
                  justifyContent: "center",
                }}
              >
                <h3
                  className='my-4'
                  style={{
                    textAlign: "center",
                    width: "100px",
                    borderRadius: "4px",

                    fontWeight: "900",
                    color: "#333333",
                    backgroundColor: "#fafafa",
                    transform: "rotate(-10deg)",
                  }}
                >
                  Founder!
                </h3>
              </div>
              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://garpar.vercel.app/'
              >
                GARPAR.ar site
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={garpar}
                  alt='GARPAR.ar site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>08-2023</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>MSBroker PWA</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://www.msbroker.com.ar/'
              >
                New PWA version of MSBroker's web.
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={pwa1}
                  alt='MSB site logo en celular'
                />
              </div>
              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={pwa2}
                  alt='MSB site splash'
                />
              </div>
              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={pwa3}
                  alt='MSB site en celular'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>04-2023</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Obra Social Digital</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://obrasocialdigital.com.ar/'
              >
                Obra Social Digital
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={osdi}
                  alt='OSDI site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>02-2023</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Cripto Capital Academy</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://v2.criptocapital.ar/'
              >
                Cripto Capital Academy
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={Cca}
                  alt='CCA site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>01-2023</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>MSBroker</h2>
              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://www.msbroker.com.ar/'
              >
                MSBroker
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={msb2}
                  alt='MSB v2 site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>12-2022</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>My Forex Plan</h2>
              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://www.myforexplan.com.ar/'
              >
                My Forex Plan
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={mfp}
                  alt='MFP site'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>08-2022</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Nohayflix</h2>
              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://630fde1fa0f77706da331b43--delightful-granita-8faa4d.netlify.app/'
              >
                Netflix's parody
              </a>
              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={nhf}
                  alt='Nohayflix site'
                />
              </div>
              <p>Free API + React.js + Redux.</p>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>06-2022</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>YLOU</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='http://ylou.com.ar/'
              >
                Yanina Loureiro's portfolio
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={ylou}
                  alt='Página web portfolio Yanina Loureiro'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>04-2022</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>NA & Asociados</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://nayasociados.com.ar/'
              >
                NA & Asociados website
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={naya}
                  alt='Página web NA Y Asociados'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>02-2022</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Finca del Sacramento</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://fervent-mayer-6497ea.netlify.app'
              >
                Finca del Sacramento's website
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={fds}
                  alt='Página web Finca del Sacramento'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>12-2021</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Colonia Express</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://affectionate-shannon-99f59f.netlify.app'
              >
                Colonia Express website
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={coex}
                  alt='Página web CoEx'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>11-2021</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Landing CoEx FIT</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://coloniaexpress.com/lp/fit/'
              >
                Landing CoEx FIT
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={coex2}
                  alt='view landing CoEx FIT'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>11-2021</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>CoEx FIT tablet game</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://coloniaexpress.com/lp/fit/juego/'
              >
                CoEx FIT tablet game
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={coex3}
                  alt='view juego CoEx FIT'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>5-2021</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>Personal portfolio</h2>

              <div className='underline'>www.vgl.com.ar</div>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={vglport}
                  alt='vista web portfolio'
                />
              </div>
            </div>

            <div className='container-vertical-line'>
              <span></span>
              <span className='vertical-line' style={{ height: "5rem" }}></span>
            </div>
          </div>
        </div>

        <div className='entry'>
          <p className='entry-date'>06-2016</p>
          <div className='entry-content'>
            <div className='entry-content-card'>
              <h2>MSBroker</h2>

              <a
                className='underline hover-this'
                target='_blank'
                rel='noreferrer'
                href='https://www.msbroker.com.ar'
              >
                www.MSBroker.com.ar
              </a>

              <div>
                <img
                  loading='lazy'
                  className='entry-content-img'
                  src={msb}
                  alt='vista web msbroker'
                />
              </div>
            </div>

            <div className='container-vertical-dotted-line'>
              <span></span>
              <span
                className='vertical-dotted-line'
                style={{ height: ".3rem" }}
              ></span>

              <span
                className='vertical-dotted-line'
                style={{ height: ".3rem" }}
              ></span>

              <span
                className='vertical-dotted-line'
                style={{ height: ".3rem" }}
              ></span>
            </div>
          </div>
        </div>
      </main>

      <img
        loading='lazy'
        className='slide-in'
        id='VGL'
        src={vgl}
        alt='VGL pic'
      />
    </>
  );
}

export default Blog;
