import cv from "./../../assets/CV-Valentin-Gonzalez-Loureiro.pdf";
import vgl from "./../../assets/VGL.png";

function Cv() {
  return (
    <>
      <main className='df'>
        <h1 className=''>Curriculum Vitae</h1>
        <p>
          I am passionate about programming. I am interested in front-end
          languages ​​and frameworks, my detail-oriented nature forces me to
          focus on design and user experience. I believe that responsibility and
          commitment are the foundations of efficient work. I developed the
          ability to perform multiple tasks effectively, adapting to dynamic
          sectors.
        </p>
        <a className='hover-this' target='_blank' href={cv}>
          <h3 className='download underline'>Download CV</h3>
        </a>

        <div className='text-center'>
          <h2 className='underline '>Stack</h2>
          <ul className='tecno-content'>
            <li>Angular</li>
            <li>React.js</li>
            <li>Ionic</li>
            <li>Tailwind</li>
            <li>Storybook</li>
            <li>Chromatic</li>
            <li>Bootstrap</li>
            <li>Astro</li>
            <li>HTML5</li>
            <li>CSS3</li>
            <li>SASS</li>
            <li>Next.js</li>
            <li>JavaScript</li>
            <li>Typescript</li>
            <li>Vercel</li>
            <li>Java</li>
            <li>.NET</li>
            <li>Node.js</li>
            <li>Express</li>
            <li>MongoDB</li>
            <li>MySQL</li>
            <li>GIT</li>
            <li>GitHub</li>
            <li>Gitlab</li>
            <li>BitBucket</li>
            <li>Netlify</li>
            <li>Firestore</li>
            <li>Firebase</li>
            <li>Figma</li>
            <li>Shopify Liquid</li>
          </ul>
        </div>

        <div>
          <h2 className='underline text-center'>Study</h2>
          <div className='study-content'>
            <div className='study-content-card'>
              <h3>Lic. en Ciencias de Datos</h3>
              <h4>UBA - 2020 (En curso)</h4>
            </div>
            <div className='study-content-card'>
              <h3>Full Stack Web Developer Angular / TypeScript</h3>
              <h4>UNC (Icaro) 2021</h4>
            </div>
            <div className='study-content-card'>
              <h3>Full Stack Web Developer Angular / JAVA</h3>
              <h4>Codo a Codo 4.0 (GCBA) 2021</h4>
            </div>
            <div className='study-content-card'>
              <h3>Full Stack Web Developer Angular / JAVA</h3>
              <h4>Argentina Programa 2021 (En curso)</h4>
            </div>
            <div className='study-content-card'>
              <h3>Front End Web Developer Javascript</h3>
              <h4>Fundación Telefónica 2021</h4>
            </div>
            <div className='study-content-card'>
              <h3>UX/UI Design</h3>
              <h4>Educacion IT (Digit@lers) 2021</h4>
            </div>
            <div className='study-content-card'>
              <h3>PHP developer</h3>
              <h4>Polo TIC Misiones 2021</h4>
            </div>
          </div>
        </div>

        <div>
          <h2 className='underline text-center'>Work</h2>
          <div className='job-content'>
            <article className='job-content-card'>
              <h2>GARPAR.ar</h2>
              <h3>CEO, CTO, Full Stack Developer...</h3>
              <div
                style={{
                  display: "flex",
                  padding: "1rem",
                  alignItems: "center,",
                  justifyContent: "center",
                }}
              >
                <h3
                  className='my-4'
                  style={{
                    textAlign: "center",
                    width: "100px",
                    borderRadius: "4px",

                    fontWeight: "900",
                    color: "#333333",
                    backgroundColor: "#fafafa",
                    transform: "rotate(-10deg)",
                  }}
                >
                  Founder!
                </h3>
              </div>
              <p className='my-4'>10/2023 - Present</p>
              <p>
                <strong>Stack:</strong> NEXT.js, HTML5, CSS3, SASS, Storybook,
                Javascript, Typescript, Tailwind, Firestore, Firebase, GIT,
                Vercel, GitHub
              </p>
            </article>

            <article className='job-content-card'>
              <h2>+Beryll</h2>
              <h3>Front End Developer</h3>

              <p className='my-4'>01/2024 - Present</p>
              <p>
                <strong>Stack:</strong> Shopify Liquid, HTML5, CSS3, SASS,
                Javascript, GIT, GitHub, SEO
              </p>
            </article>

            <article className='job-content-card'>
              <h2>RIU</h2>
              <h3>Front End Developer</h3>
              <p className='my-4'>05/2023 - Present</p>
              <p>
                <strong>Stack:</strong> Angular, HTML5, CSS3, SASS,
                MicroFrontends, RIU Library, Javascript, Typescript, GIT,
                OpenProyect, Gitlab, Jenkins, Sonar, Agile, Scrum
              </p>
            </article>

            <article className='job-content-card'>
              <h2>Technisys</h2>
              <h3>Front End Developer</h3>
              <p className='my-4'>05/2022 - 05/2023</p>
              <p>
                <strong>Stack:</strong> React.js, Angular, HTML5, CSS3, SASS,
                Storybook, Styled-components, Javascript, Typescript, Bootstrap,
                Tailwind, GIT, Jira, Tempo, Confluence, BitBucket, GitHub,
                Agile, Scrum
              </p>
            </article>

            <article className='job-content-card'>
              <h2>Colonia Express</h2>
              <h3>Full Stack Developer</h3>
              <p className='my-4'>11/2021 - 05/2022</p>
              <p>
                <strong>Stack:</strong> Angular, React.js, Ionic, HTML5, CSS3,
                Bootstrap, .NET, TypeScript, GIT, SEO, GitHub, User Experience
                Design, Research, WorkFlows, UI design, Voice & Tone,
                StoryTelling, Prototyping, Tests
              </p>
            </article>

            <article className='job-content-card'>
              <h2>MSBroker</h2>
              <h3>Web Developer</h3>
              <p className='my-4'>02/2016 - 10/2021</p>
              <p>
                <strong>Stack:</strong> HTML5, CSS3, JavaScript, SEO, Google ADS
                and Analytics, User Experience Design, Research, Prototyping,
                Graphic design, Art design, Branding, Digital and media graphic
                advertising
              </p>
            </article>
          </div>
        </div>

        <div className='lang text-center'>
          <h2 className='underline '>Languages</h2>
          <p>Native Spanish</p>
          <p>English B2</p>
        </div>
      </main>

      <img
        loading='lazy'
        className='slide-in'
        id='VGL'
        src={vgl}
        width='200'
        alt='VGL pic'
      />
    </>
  );
}

export default Cv;
