import React, { useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Outlet, useLocation } from "react-router-dom";
import MainContainer from "./../../components/maincontainer/MainContainer";
import Menu from "./../../components/menu/Menu";

export default function Root() {
  const cursorRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const links = document.querySelectorAll(".hover-this");

    const animateCursor = (e) => {
      const span = cursorRef.current;
      if (!span) return;

      const { offsetX: x, offsetY: y, target } = e;
      const { offsetWidth: width, offsetHeight: height } = target;
      const move = 0;
      const xMove = (x / width) * (move * 2) - move;
      const yMove = (y / height) * (move * 2) - move;

      span.style.transform = `translate(${xMove}px, ${yMove}px) scale(6)`;
    };

    const editCursor = (e) => {
      const span = cursorRef.current;
      if (!span) return;

      const { clientX: x, clientY: y } = e;
      span.style.left = x + "px";
      span.style.top = y + "px";
    };

    links.forEach((link) => {
      link.addEventListener("mousemove", animateCursor);
      link.addEventListener("mouseleave", () => {
        const span = cursorRef.current;
        if (span) span.style.transform = "";
      });
    });

    window.addEventListener("mousemove", editCursor);

    return () => {
      links.forEach((link) => {
        link.removeEventListener("mousemove", animateCursor);
      });
      window.removeEventListener("mousemove", editCursor);
    };
  }, [location]);

  return (
    <Scrollbars
      // This will activate auto hide
      autoHide
      // Hide delay in ms
      autoHideTimeout={1000}
      // Duration for hide animation in ms.
      autoHideDuration={200}
      autoHeight
      autoHeightMin={"100vh"}
      universal
    >
      <MainContainer>
        <Menu />
        <Outlet />
        <div className='cursor' ref={cursorRef}></div>
      </MainContainer>
    </Scrollbars>
  );
}
