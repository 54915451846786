import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./normalize.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Blog from "./pages/blog/Blog.jsx";
import Cv from "./pages/cv/CV.jsx";
import Home from "./pages/home/Home";
import Root from "./pages/root/Root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,

    children: [
      {
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "cv",
            element: <Cv />,
          },
          {
            path: "blog",
            element: <Blog />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
