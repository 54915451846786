import { Link } from "react-router-dom";
import LogoVGL from "./../../assets/LogoVGL.png";

const Menu = () => {
  return (
    <header>
      <Link className='header-logo' to='/'>
        <img loading='lazy' src={LogoVGL} alt='logo' />
      </Link>

      <ul className='menu ul'>
        <li className='li hover-this'>
          <Link to='/'>
            Home
            <span className='border border-top'></span>
            <span className='border border-right'></span>
            <span className='border border-bottom'></span>
            <span className='border border-left'></span>
          </Link>
        </li>
        <li className='li hover-this'>
          <Link to='/cv'>
            CV
            <span className='border border-top'></span>
            <span className='border border-right'></span>
            <span className='border border-bottom'></span>
            <span className='border border-left'></span>
          </Link>
        </li>
        <li className='li hover-this'>
          <Link to='/blog'>
            Blog
            <span className='border border-top'></span>
            <span className='border border-right'></span>
            <span className='border border-bottom'></span>
            <span className='border border-left'></span>
          </Link>
        </li>
      </ul>
    </header>
  );
};

export default Menu;
